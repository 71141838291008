import React from "react";
import { FetchBadgeSelectors } from "./FetchBadgeSelectors";

type FetchBadgeProps = {
  top: string;
  left: string;
};

export const FetchBadge: React.FC<FetchBadgeProps> = (props) => {
  return (
    <div
      data-cy={FetchBadgeSelectors.block}
      className="position-absolute"
      style={{
        top: props.top,
        left: props.left,
        backgroundColor: "red",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
      }}
    />
  );
};
