import { NavRoute, navRoutePathConstants } from "routes/navRoutes";
import { useUserInteractions } from "./useUserInteractions";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { Location } from "history";

/**
 *
 * @param location The return value of useLocation
 * @returns Methods for interacting with the userInteractions store.
 * @description This hook isolates the business logic of the badges. It's currently based on local state and does not support global usage.
 */
export function useBadges(location: Location): {
  getRouteBadgeStatus: (route: NavRoute) => boolean;
} {
  const [userInteractions, { updateUserInteractions }] = useUserInteractions();

  const marketBadgeTargetDate = userInteractions?.market?.targetDate;
  const marketBadgeLastSeen = userInteractions?.market?.lastSeen;
  const hasSeenMarketBadge =
    !!marketBadgeTargetDate &&
    !!marketBadgeLastSeen &&
    moment(marketBadgeLastSeen).isSameOrAfter(moment(marketBadgeTargetDate));
  const currentMarketBadgeTargetDate = moment(
    "12/12/24",
    "MM/DD/YYYY",
  ).toDate();

  useEffect(() => {
    // Set market badge target date if not set
    if (!!marketBadgeTargetDate) {
      if (
        moment(marketBadgeTargetDate, "MM/DD/YYYY").isBefore(
          currentMarketBadgeTargetDate,
        )
      ) {
        updateUserInteractions({
          market: {
            ...userInteractions.market,
            targetDate: currentMarketBadgeTargetDate,
          },
        });
      }
    } else {
      updateUserInteractions({
        market: {
          targetDate: currentMarketBadgeTargetDate,
        },
      });
    }
  }, [
    currentMarketBadgeTargetDate,
    marketBadgeTargetDate,
    updateUserInteractions,
    userInteractions,
  ]);

  useEffect(() => {
    // Disable badge if the current page is market
    if (
      location.pathname.includes(navRoutePathConstants.market.home) &&
      !hasSeenMarketBadge
    ) {
      updateUserInteractions({
        market: {
          targetDate: currentMarketBadgeTargetDate,
          lastSeen: currentMarketBadgeTargetDate,
        },
      });
    }
  }, [
    currentMarketBadgeTargetDate,
    hasSeenMarketBadge,
    location.pathname,
    updateUserInteractions,
    userInteractions,
  ]);

  const getRouteBadgeStatus = useCallback(
    (route: NavRoute): boolean => {
      switch (route.path) {
        case navRoutePathConstants.market.home:
          return !hasSeenMarketBadge;
        default:
          return false;
      }
    },
    [hasSeenMarketBadge],
  );

  return useMemo((): ReturnType<typeof useBadges> => {
    return { getRouteBadgeStatus };
  }, [getRouteBadgeStatus]);
}
