import { FetchLocalStorage } from "helpers/localStorage/LocalStorage";
import { localStorageGetItemSafe } from "helpers/localStorage/localStorageGetItemSafe";
import { localStorageSetItemSafe } from "helpers/localStorage/localStorageSetItemSafe";
import { useState, useEffect, useMemo } from "react";

export function useLocalStorage<Key extends keyof FetchLocalStorage>({
  key,
  fallbackValue,
}: {
  key: Key;
  fallbackValue?: FetchLocalStorage[Key];
}): [
  FetchLocalStorage[Key],
  { setValue(value: FetchLocalStorage[Key]): void },
] {
  const [value, setValue] = useState<FetchLocalStorage[Key]>(() => {
    return localStorageGetItemSafe(key) || fallbackValue || undefined;
  });

  useEffect(() => {
    localStorageSetItemSafe(key, value);
  }, [key, value]);

  return useMemo(() => [value, { setValue }], [value]);
}
