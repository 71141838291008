import {
  UserInteractions,
  FetchLocalStorage,
} from "helpers/localStorage/LocalStorage";
import { useLocalStorage } from "helpers/useLocalStorage";
import { useMemo } from "react";

const defaultInteractions: UserInteractions = {
  market: {},
};
export function useUserInteractions(): [
  FetchLocalStorage["userInteractions"],
  {
    updateUserInteractions(
      update: Partial<FetchLocalStorage["userInteractions"]>,
    ): void;
  },
] {
  const [userInteractions, { setValue: setUserInteractions }] = useLocalStorage(
    {
      key: "userInteractions",
      fallbackValue: defaultInteractions,
    },
  );

  return useMemo((): ReturnType<typeof useUserInteractions> => {
    return [
      userInteractions,
      {
        updateUserInteractions(update) {
          const currentInteractionState = !!userInteractions
            ? userInteractions
            : defaultInteractions;

          setUserInteractions({ ...currentInteractionState, ...update });
        },
      },
    ];
  }, [setUserInteractions, userInteractions]);
}
