import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import warningCircle from "assets/img/warning-circle.svg";
import { FetchIcon } from "views/common/misc/FetchIcon";
import { NavLinkPropsBase } from "./NavLinkPropsBase";
import { FetchBadge } from "./FetchBadge";

const classes = generateBemClassNames({
  block: { name: "Sidebar" },
  elements: [{ name: "link" }, { name: "viewSubmenuCaret", extras: ["caret"] }],
});

type FetchNavLinkCollapseProps = NavLinkPropsBase;

const Component: React.FC<FetchNavLinkCollapseProps> = (
  props: FetchNavLinkCollapseProps,
) => {
  const { route } = props;
  const location = useLocation();
  const active = location.pathname === route.path;

  return (
    <li
      className={classes.elements.link({
        extras: [active ? "active" : ""],
      })}
    >
      <NavLink to={route.path} data-toggle="collapse">
        {route.icon !== undefined ? (
          <div className="d-flex position-relative">
            {props.badge && <FetchBadge top={"3px"} left={"-7px"} />}
            <FetchIcon
              iconType={route.icon}
              size="lg"
              className="fa-pull-left"
            />
            <p>{route.name}</p>
            {route.inErrorState && (
              <img
                className="sidebar-error"
                style={{ marginLeft: "10px" }}
                src={warningCircle}
              />
            )}
          </div>
        ) : (
          <div className="d-flex position-relative">
            {props.badge && <FetchBadge top={"3px"} left={"-7px"} />}
            <span className="sidebar-mini-icon" />
            <span className="sidebar-normal">{route.name}</span>
          </div>
        )}
      </NavLink>
    </li>
  );
};

export const FetchNavLink = React.memo(Component);
